












import Vue from 'vue';
import ApiService from '@/services/ApiService';
import ToastMessage from '@/models/ToastMessage';
import { MetaInfo } from 'vue-meta';

export default Vue.extend({
  props: {
    token: {
      type: String,
    },
    userId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      errorMessage: '',
    };
  },
  metaInfo(): MetaInfo {
    return { title: this.$t('pages.auth.ConfirmEmail.meta.title').toString() };
  },
  mounted() {
    this.loadData();
    this.setBreadCrumb();
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.auth.ConfirmEmail.breadcrumb.last' },
      ]);
    },
    loadData() {
      this.loading = true;
      const path = '/api/Auth/confirmEmail?token=' + this.token + '&userId=' + this.userId;
      ApiService.get(path).then(
        () => {
          this.loading = false;
          var toastMessage = this.$t('pages.auth.ConfirmEmail.successToastMessage').toString();
          this.$store.commit('addToastMessage', new ToastMessage(toastMessage, 'bg-success'));
          this.$router.push({ name: 'Login' });
        },
        (error) => {
          this.loading = false;
          this.errorMessage = error;
          var toastMessage = this.$t('forms.common.generic_error').toString();
          this.$store.commit('addToastMessage', new ToastMessage(toastMessage, 'bg-warning'));
        }
      );
    },
  },
});
